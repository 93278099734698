//@ts-ignore
import Title_1 from '../assets/Minimalism/Title_1.jpg'
//@ts-ignore
import Title_2 from '../assets/Minimalism/Title_2.jpg'
//@ts-ignore
import Title_3 from '../assets/Minimalism/Title_3.jpg'
//@ts-ignore
import Title_4 from '../assets/Minimalism/Title_4.jpg'
//@ts-ignore
import Title_5 from '../assets/Minimalism/Title_5.jpg'
//@ts-ignore
import Title_6 from '../assets/Minimalism/Title_6.jpg'
//@ts-ignore
import Title_7 from '../assets/Minimalism/Title_7.jpg'
//@ts-ignore
import Title_8 from '../assets/Minimalism/Title_8.jpg'
//@ts-ignore
import Title_9 from '../assets/Minimalism/Title_9.jpg'
//@ts-ignore
import Title_10 from '../assets/Minimalism/Title_10.jpg'
//@ts-ignore
import Title_11 from '../assets/Minimalism/Title_11.jpg'
//@ts-ignore
import Title_12 from '../assets/Minimalism/Title_12.jpg'
//@ts-ignore
import Title_13 from '../assets/Minimalism/Title_13.jpg'
//@ts-ignore
import Title_14 from '../assets/Minimalism/Title_14.jpg'
//@ts-ignore
import Title_15 from '../assets/Minimalism/Title_15.jpg'
//@ts-ignore
import Title_16 from '../assets/Minimalism/Title_16.jpg'
//@ts-ignore
import Title_17 from '../assets/Minimalism/Title_17.jpg'
//@ts-ignore
import Title_18 from '../assets/Minimalism/Title_18.jpg'
//@ts-ignore
import Title_19 from '../assets/Minimalism/Title_19.jpg'
//@ts-ignore
import Title_20 from '../assets/Minimalism/Title_20.jpg'
//@ts-ignore
import Title_21 from '../assets/Minimalism/Title_21.jpg'
//@ts-ignore
import Title_22 from '../assets/Minimalism/Title_22.jpg'
//@ts-ignore
import Title_23 from '../assets/Minimalism/Title_23.jpg'
//@ts-ignore
import Title_24 from '../assets/Minimalism/Title_24.jpg'
//@ts-ignore
import Title_25 from '../assets/Minimalism/Title_25.jpg'
//@ts-ignore
import Title_26 from '../assets/Minimalism/Title_26.jpg'
//@ts-ignore
import Title_27 from '../assets/Minimalism/Title_27.jpg'
//@ts-ignore
import Title_28 from '../assets/Minimalism/Title_28.jpg'
//@ts-ignore
import Title_29 from '../assets/Minimalism/Title_29.jpg'
//@ts-ignore
import Title_30 from '../assets/Minimalism/Title_30.jpg'
//@ts-ignore
import Title_31 from '../assets/Minimalism/Title_31.jpg'
//@ts-ignore
import Title_32 from '../assets/Minimalism/Title_32.jpg'
//@ts-ignore
import Title_33 from '../assets/Minimalism/Title_33.jpg'
//@ts-ignore
import Title_34 from '../assets/Minimalism/Title_34.jpg'
//@ts-ignore
import Title_35 from '../assets/Minimalism/Title_35.jpg'
//@ts-ignore
import Title_36 from '../assets/Minimalism/Title_36.jpg'
//@ts-ignore
import Title_37 from '../assets/Minimalism/Title_37.jpg'
//@ts-ignore
import Title_38 from '../assets/Minimalism/Title_38.jpg'
//@ts-ignore
import Title_39 from '../assets/Minimalism/Title_39.jpg'
//@ts-ignore
import Title_40 from '../assets/Minimalism/Title_40.jpg'
//@ts-ignore
import Title_41 from '../assets/Minimalism/Title_41.jpg'

export const MinimalismImages: string[] = [
  Title_1,
  Title_2,
  Title_3,
  Title_4,
  Title_5,
  Title_6,
  Title_7,
  Title_8,
  Title_9,
  Title_10,
  Title_11,
  Title_12,
  Title_13,
  Title_14,
  Title_15,
  Title_16,
  Title_17,
  Title_18,
  Title_19,
  Title_20,
  Title_21,
  Title_22,
  Title_23,
  Title_24,
  Title_25,
  Title_26,
  Title_27,
  Title_28,
  Title_29,
  Title_30,
  Title_31,
  Title_32,
  Title_33,
  Title_34,
  Title_35,
  Title_36,
  Title_37,
  Title_38,
  Title_39,
  Title_40,
  Title_41,
]